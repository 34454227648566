// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ad-campaign-contact-us-jsx": () => import("./../../../src/pages/ad-campaign/contact-us.jsx" /* webpackChunkName: "component---src-pages-ad-campaign-contact-us-jsx" */),
  "component---src-pages-buy-with-confidence-jsx": () => import("./../../../src/pages/buy-with-confidence.jsx" /* webpackChunkName: "component---src-pages-buy-with-confidence-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-materials-jsx": () => import("./../../../src/pages/materials.jsx" /* webpackChunkName: "component---src-pages-materials-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-templates-service-service-jsx": () => import("./../../../src/templates/Service/Service.jsx" /* webpackChunkName: "component---src-templates-service-service-jsx" */)
}

