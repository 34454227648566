/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const scrollToElement = require('scroll-to-element');

const checkHash = (hash) => {
  if (hash) {
    scrollToElement(hash, {
      offset: -90,
      duration: 1000,
    });
  }
};

exports.onRouteUpdate = ({ location }) => {
  checkHash(location.hash);
};
